<template>
  <div class="container">
    <ServicesSection/>
  </div>
</template>

<script>

import ServicesSection from '@/components/ServicesSection.vue';

export default {
  components: { ServicesSection },
};
</script>

<style scoped>

</style>
