<template>
  <nav class="nav">
    <div class="nav-content">
      <div class="logo">
        <img src="@/assets/images/logo.png" alt="logo" class="logo-img"/>
        Cedora Tech
      </div>
      <ul>
        <li>
          <router-link to="/" exact-active-class="active" class="nav-link">
            Home
          </router-link>
        </li>
        <li>
          <router-link to="/about" active-class="active" class="nav-link">
            About Us
          </router-link>
        </li>
        <li>
          <router-link to="/services" active-class="active" class="nav-link">
            Services
          </router-link>
        </li>
        <li>
          <router-link to="/cases" active-class="active" class="nav-link">
            Case
          </router-link>
        </li>
      </ul>
      <button @click="openWhatsAppChat">Contact Us</button>
    </div>
  </nav>
</template>

<script>
export default {
  methods: {
    openWhatsAppChat() {
      const phoneNumber = '330602564326'; // 替换为目标WhatsApp账号的号码，去掉+号和空格
      const message = 'Hello, I would like to chat with you about corporate with Cedora Tech!'; // 预填的消息
      const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
      window.open(whatsappUrl, '_blank'); // 打开新的浏览器标签页
    },
  }
};
</script>
<style scoped>
/* 导航栏整体样式 */
.nav {
  max-width: 1200px; /* 最大宽度为1200px */
  /*margin-top: 50px;
  //margin-left: 150px;*/
  margin: 0 auto;
  display: flex; /* 使用Flexbox布局 */
  justify-content: center; /* 两端对齐 */
  align-items: center; /* 垂直居中 */
  padding: 0 20px; /* 添加左右内边距 */
  height: 80px; /* 固定高度 */
  background-color: #1C1C1C;
  border: 1px solid #262626;
  border-radius: 100px;
}

.logo-img{
  width: 30px;
}

.nav-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  max-width: 1200px;
}

.logo {
  font-size: 1.5rem;
  margin-left: -90px;
  /*font-weight: bold;
  color: #00ff88;*/
}

/* 导航链接样式 */
.nav ul {
  display: flex;
  list-style: none;
  gap: 1rem;
  margin: 0;
  padding: 0;

}

.nav ul li .nav-link {
  color: #fff;

  text-decoration: none;
  transition: all 0.3s ease;
  position: relative;
  padding: 0.5rem 1rem;
}

/* 激活状态样式 */
.nav ul li .nav-link.active::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 4px;
  background-color: #c1ff27;
  border-radius: 2px;
}

.nav ul li:first-child .nav-link.active::after {
  /* Home 的背景不显示 */
  background: none;
}

.nav ul li .nav-link:hover {
  color: #c1ff27;
}

/* 按钮样式 */
button {
  background-color: #c1ff27;
  color: #000;
  border: none;
  border-radius: 1.5rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #a8d422; /* Slightly darker hover effect */
}
</style>
