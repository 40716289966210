<template>
  <section class="bg-black text-white text-center py-16 container">
    <!-- Reusable Intro Section -->
    <div class="intro-container">
      <img src="@/assets/images/Icon01.png" alt="Icon" class="icon" />
      <p class="intro-text">Easy, fast, accessible.</p>
    </div>

    <!-- Hero Section -->
    <div class="hero-container">
      <h1 class="hero-title">
        Welcome to Cedora Tech. <br>
        Empowering Your <span class="highlight">Self-designed</span> <br>
        <span class="highlight">Software Journey</span>
      </h1>
    </div>

    <!-- Description -->
    <p class="description">
      Cedora Technology is an innovative technology company specializing in
      software development and tailored digital solutions. Headquartered in Hong
      Kong, we are committed to delivering efficient, reliable, and customized services to
      clients worldwide, empowering businesses to thrive in an ever-evolving
      market.
    </p>
    <button class="custom-button">See More</button>
  </section>
  <section class="bg-black text-white text-center py-16 container">
    <!-- Vision Section -->
    <div class="intro-container">
      <img src="@/assets/images/Icon01.png" alt="Icon" class="icon" />
      <p class="intro-text">Easy, fast, accessible.</p>
    </div>
    <div class="hero-container">
      <h1 class="hero-title vision-title">
        <span class="highlight-alt">願景 | Our Vision <br /> Innovating Solutions, Empowering Futures.</span>
      </h1>
    </div>
    <p class="description">
      煦杉科技致力於成為技術創新領域的領軍企業，為全球客戶創造持久價值，攜手開啟數字化未來。</p>
    <p class="description">
      Cedora Technology is committed to becoming a leader in technological innovation, creating long-lasting value for global clients, <br />
      and shaping a digital future together.
    </p>
    <button class="custom-button">See More</button>
  </section>
  <section class="bg-black text-white text-center py-16 container">

  </section>
</template>

<script>
export default {};
</script>

<style scoped>
/* Container Styling */
.container {
  max-width: 1200px;
  margin: 0 auto;
  background-image: url('@/assets/images/backgroundRT01.png'); /* 设置背景图片路径 */
  background-position: top right; /* 背景图片定位于右上角 */
  background-repeat: no-repeat; /* 防止背景图片重复 */
  background-size: auto; /* 根据需要调整图片大小，例如 auto、cover 或 contain */
}

/* Reusable Intro Section Styles */
.intro-container {
  display: flex;
  align-items: center;
  margin: 100px auto 0px;
  height: 41px;
  gap: 6px;
  border-radius: 61px;
  background-color: rgba(255, 255, 255, 0.05);
}

/* Shared Icon Styles */
.icon {
  width: 19.5px;
  height: 19.5px;
  opacity: 0.8;
}

/* Shared Text Styles */
.intro-text,
.description {
  font-family: "Lexend", sans-serif;
  font-size: 18px;
  font-weight: 300;
  line-height: 24px;
  color: #e4e4e7;
}

/* Hero Section Styles */
.hero-container {
  margin: 0 auto 30px;
  text-align: left;
}

.hero-title {
  font-family: "Lexend", sans-serif;
  font-size: 48px;
  font-weight: 500;
  line-height: 72px;
  color: #ffffff;
  margin-top: 1px;
  margin-bottom: 1px;
}

.vision-title .highlight-alt {
  color: #14ae5c;
  font-weight: 500;
}

/* Highlighted Text */
.highlight {
  color: #c1ff27;
  font-weight: 500;
}

/* Buttons */
.custom-button {
  background: #caff33;
  padding: 18px 30px;
  height: 40px;
  line-height: 2px;
  margin-top: 20px;
  border-radius: 82px;
  font-family: "Lexend", sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: #1c1c1c;
  cursor: pointer;
}

</style>
