<template>
  <div class="container">
    <HeroSection />
  </div>
  <div class="sub-container"></div>
</template>

<script>
import HeroSection from '@/components/HeroSection.vue';


export default {
  components: {  HeroSection },
};
</script>

<style scoped>
.sub-container{
  padding: 30px;
}
</style>
