import { createRouter, createWebHistory } from 'vue-router';
import Home from '@/views/HomePage.vue';
import About from '@/views/AboutPage.vue';
import Services from '@/views/ServicesPage.vue';
import Cases from '@/views/CasesPage.vue';
import PolicyPrivacy from '@/views/PrivacyPolicy.vue';
import ServiceTerms from '@/views/TermofService.vue';

const routes = [
    { path: '/', component: Home },
    { path: '/about', component: About },
    { path: '/services', component: Services },
    { path: '/cases', component: Cases },
    { path: '/terms-of-service', component: ServiceTerms },
    { path: '/privacy-policy', component: PolicyPrivacy },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
