<template>
  <footer class="bg-gray-900 text-white py-6 container">
    <div class="container-icon">
      <a href="https://www.facebook.com" target="_blank">
        <img src="../assets/images/facebook.png" alt="Facebook Logo" class="footer-icon"/>
      </a>
      <a href="https://www.twitter.com" target="_blank">
        <img src="../assets/images/twitter.png" alt="Twitter Logo" class="footer-icon"/>
      </a>
      <a href="https://www.instagram.com" target="_blank">
        <img src="../assets/images/ins.png" alt="Instagram Logo" class="footer-icon"/>
      </a>
    </div>
    <div class=" mx-auto text-center">
      <p>Copyright &copy;  [2025] Cedora Technology (Hong Kong) Limited. All rights reserved.</p>
    </div>
    <div class="footer-links">
<!--      <a href="/privacy-policy" target="_blank">Privacy Policy</a>class="nav-link"-->
      <router-link to="/privacy-policy" active-class="active">
        Privacy Policy
      </router-link>
      <span>|</span>
      <router-link to="/terms-of-service" active-class="active">
        Terms of Service
      </router-link>
<!--      <a href="/terms-of-service" target="_blank">Terms of Service</a>-->
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style scoped>
.container{
  max-width: 1200px;
  height: 5em;
  margin: 0 auto;
  padding: 0 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.container-icon{
  display: flex;
  justify-content: space-between;
}

.footer-icon{
  width: 40px;
  height: 40px;
  margin-left: 10px;
  cursor: pointer; /* 鼠标样式变成手指状 */
  transition: transform 0.2s ease; /* 增加微动画效果（可选） */
}

.footer-icon:hover {
  transform: scale(1.1); /* 鼠标悬停时放大图片（可选） */
}

a {
  text-decoration: none; /* 去掉链接文字的下划线 */
}

.footer-links{
  display: flex;
  justify-content: space-between;
  font-size: 15px;
  font-family: "Arial Narrow",sans-serif;
}
.footer-links a {
  text-decoration: none; /* 移除默认下划线 */
  color: inherit; /* 继承父元素的颜色 */
  cursor: pointer;
}

.footer-links a:hover {
  text-decoration: underline; /* 悬停时显示下划线 */
  color: #00ff88; /* 可选，悬停时更改颜色 */
}

footer {
  background-color: #111;
  color: rgba(255, 255, 255, 0.6);
  text-align: center;
  padding: 2rem 0;
}

footer p {
  margin: 0;
}

footer a {
  color: #00ff88;
}

footer a:hover {
  color: #00cc6e;
}


</style>
