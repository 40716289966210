<template>
  <div class="terms-of-service container">
    <h1>Term of Service</h1>
    <p >
      Welcome to Cedora Technology. By accessing or using our website, services, or products, you agree to comply with and be bound by these Terms of Service. If you do not agree to these Terms, please do not use our Services.
    </p>

    <div class="section">
      <h2>1. Acceptance of Terms</h2>
      <p class="p-container">
        By accessing or using our Services, you confirm that you have read, understood, and agree to these Terms and any related policies, including our Privacy Policy.
      </p>
    </div>

    <div class="section">
      <h2>2. Changes to Terms</h2>
      <p class="p-container">
        We reserve the right to modify these Terms at any time. Changes will be effective immediately upon posting. Your continued use of the Services constitutes acceptance of the revised Terms.
      </p>
    </div>

    <div class="section">
      <h2>3. Use of Services</h2>
      <p class="p-container">
        (a) You must be at least 18 years old or have the consent of a legal guardian to use our Services.
        <br/>(b) You agree to use the Services only for lawful purposes and in compliance with all applicable laws and regulations.
        <br/>(c) You may not use our Services to distribute harmful or illegal content or to infringe on the rights of others.
      </p>
    </div>

    <div class="section">
      <h2>4. Intellectual Property</h2>
      <p class="p-container">
        All content, trademarks, and materials available on our Services are owned by Cedora Technology or our licensors. You may not use, copy, or distribute any content without our prior written consent.
      </p>
    </div>

    <div class="section">
      <h2>5. Limitation of Liability</h2>
      <p class="p-container">
        To the fullest extent permitted by law, Cedora Technology shall not be liable for any indirect, incidental, or consequential damages arising from your use of our Services.
      </p>
    </div>

    <div class="section">
      <h2>6. Governing Law</h2>
      <p class="p-container">
        These Terms shall be governed by and construed in accordance with the laws of Hong Kong.
      </p>
    </div>

    <div class="section">
      <h2>7. Contact Us</h2>
      <p class="p-container">
        For questions about these Terms, please contact us at <span style="color: #b6ff00">nizhou@link.cuhk.edu.cn.</span>.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "TermsOfService",
};
</script>

<style scoped>
.terms-of-service {
  margin-top: 30px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #fff;
  background-color: #222;
  padding: 20px;
  border-radius: 25px;
}

.terms-of-service h1 {
  color: #b6ff00;
  margin-bottom: 20px;
}

.terms-of-service h2 {
  color: #fff;
  margin-top: 20px;
}

.terms-of-service p {
  margin-bottom: 15px;
}

.section {
  margin-bottom: 20px;
}
.p-container{
  padding: 0px 25px;
}
</style>
