<template>
  <div class="container">
    <HeroSection />
    <ServicesSection/>
    <UseCasesSection />
  </div>
</template>

<script>
import HeroSection from '@/components/HeroSection.vue';
import ServicesSection from '@/components/ServicesSection.vue';
import UseCasesSection from '@/components/UseCasesSection.vue';

export default {
  components: { HeroSection, ServicesSection, UseCasesSection },
};
</script>

<style scoped>

</style>
