<template>
  <div class="container">
    <UseCasesSection />
  </div>
  <div class="sub-container"></div>
</template>

<script>

import UseCasesSection from '@/components/UseCasesSection.vue';

export default {
  components: { UseCasesSection },
};
</script>

<style scoped>
.sub-container{
  padding: 30px;
}
</style>
