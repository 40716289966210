<template>
  <div class="privacy-policy container">
    <h1>Privacy Policy</h1>
    <p>
      Cedora Technology is committed to protecting your privacy. This Privacy Policy explains how we collect, use, and disclose information about you when you use our website, services, or products.
    </p>

    <div class="section">
      <h2>1. Information We Collect</h2>
      <p class="p-container">
        (a) Personal Information: We may collect information such as your name, email address, phone number, and payment details when you use our Services or interact with us.
        <br/>(b) Usage Data: We collect information about how you use our Services, including IP address, browser type, and pages visited.
        <br/>(c) Cookies: We use cookies to enhance your experience on our website.
      </p>
    </div>

    <div class="section">
      <h2>2. How We Use Your Information</h2>
      <p class="p-container">
        (a) To provide, operate, and improve our Services.
        <br/>(b) To communicate with you, including responding to inquiries and sending updates.
        <br/>(c) To comply with legal obligations and prevent fraudulent activities.
      </p>
    </div>

    <div class="section">
      <h2>3. Sharing Your Information</h2>
      <p class="p-container">
        We do not sell your personal information. We may share your information with third parties in the following circumstances:
        <br/>(a) With service providers who assist us in operating our business.
        <br/>(b) When required by law or to protect our rights.
      </p>
    </div>

    <div class="section">
      <h2>4. Data Security</h2>
      <p class="p-container">
        We take reasonable measures to protect your information from unauthorized access, disclosure, or destruction. However, no system is completely secure.
      </p>
    </div>

    <div class="section">
      <h2>5. Your Rights</h2>
      <p class="p-container">
        (a) You have the right to access and update your personal information.
        <br/>(b) You may request the deletion of your data, subject to legal and contractual obligations.
        <br/>(c) You can opt-out of receiving marketing communications at any time.
      </p>
    </div>

    <div class="section">
      <h2>6. Children’s Privacy</h2>
      <p class="p-container">
        Our Services are not intended for children under the age of 13, and we do not knowingly collect personal information from them.
      </p>
    </div>

    <div class="section">
      <h2>7. Changes to This Policy</h2>
      <p class="p-container">
        We may update this Privacy Policy from time to time. Changes will be effective upon posting on our website.
      </p>
    </div>

    <div class="section">
      <h2>8. Contact Us</h2>
      <p class="p-container">
        For questions or concerns about this Privacy Policy, please contact us at <span style="color: #b6ff00">nizhou@link.cuhk.edu.cn.</span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "PrivacyPolicy",
};
</script>

<style scoped>
.privacy-policy {
  margin-top: 30px;
  border-radius: 25px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #fff;
  background-color: #222;
  padding: 20px;
}

.privacy-policy h1 {
  color: #b6ff00;
  margin-bottom: 20px;
}

.privacy-policy h2 {
  color: #fff;
  margin-top: 20px;
}

.privacy-policy p {
  margin-bottom: 15px;
}

.section {
  margin-bottom: 20px;
}

.p-container{
  padding: 0px 25px;
}
</style>
