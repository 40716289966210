<template>
  <section class="bg-gray-900 text-white py-16">
    <div class="section-header">
      <h2>Use Cases</h2>
      <p>Example of services we can provide.</p>
    </div>

    <div class="page-container">
      <div class="section-left">
        <!-- Use Cases Cards -->
        <div class="use-cases-cards">
          <div class="card" v-for="(card, index) in cards" :key="index">
            <img class="icon" :src="card.icon" alt=""/>
            <h3>{{ card.title }}</h3>
          </div>
        </div>
      </div>
      <div class="section-right">
        <div class="business-startup-section">
          <h3>For a Business Start-up</h3>
          <p>
            For individuals who want to start a business in e-commerce, you can build a website
            through us and integrate all needed payment systems to facilitate collecting money.
            Use data analytical tools to adjust your operation. Add custom features, integrate
            specific tools, or launch promotions tailored to your business needs, providing the
            agility to adapt to market trends and customer demands.
          </p>
          <div class="stats">
            <h4>up to</h4>
            <div class="stats-list">
              <div class="stat">
                <h2>30%</h2>
                <p>Cost Reduction in comparison to selling on marketplaces</p>
              </div>
              <div class="stat">
                <h2>40%</h2>
                <p>Sales Increase</p>
              </div>
              <div class="stat">
                <h2>50%</h2>
                <p>Profit margins Increase</p>
              </div>
            </div>
          </div>
      </div>
    </div>
    </div>
  </section>


  <section>
    <section class="last-section"><!--Last part-->
      <div class="last-section-text">
        <h1>
          Start your <span class="highlight">Digital Business</span> journey with
          <span class="highlight">Cedora</span> <span class="highlight-accent">today!</span>
        </h1>
        <p>
          Ready to take control of your finances? Join YourBank now, and let us help you
          achieve your financial goals with our tailored <br/>solutions and exceptional customer
          service.
        </p>
      </div>

      <button class="contact-button" @click="openWhatsAppChat">Contact Us</button>
    </section>
    <!-- Footer Section -->
    <footer class="footer-section">
      <div class="footer-logo">
        <img src="@/assets/images/logo.png" alt="" width="30" height="30">
        Cedora Tech
      </div>
      <div class="footer-links">
        <a href="#">Home</a>
        <a href="#">About Us</a>
        <a href="#">Service</a>
        <a href="#">Case</a>
      </div>
      <div class="footer-contact">
        <span class="footer-contact-sub">
          <img src="@/assets/images/IconEmail.png" alt="" width="20" height="20"/>
          <span>nizhou@link.cuhk.edu.cn</span>
        </span>
        <span class="footer-contact-sub">
          <img src="@/assets/images/WeChat.png" alt="" width="23" height="20"/>
          <span>CedoraTech</span>
        </span>
        <span class="footer-contact-sub">
          <img src="@/assets/images/IconLocation.png" alt="" width="20" height="20"/>
          <span>Somewhere in the Digital World</span>
        </span>
      </div>
    </footer>
  </section>
</template>

<script>

export default {
  data() {
    return {
      cards: [
        { icon: require("@/assets/images/IconContainer01.png"), title: "Building of your own independent e-commerce site" },
        { icon: require("@/assets/images/IconContainer02.png"), title: "API integration with payment system" },
        { icon: require("@/assets/images/IconContainer03.png"), title: "Managing income" },
        { icon: require("@/assets/images/IconContainer04.png"), title: "APP development" },
      ],
    };
  },
  methods: {
    openWhatsAppChat() {
      const phoneNumber = '330602564326'; // 替换为目标WhatsApp账号的号码，去掉+号和空格
      const message = 'Hello, I would like to chat with you about corporate with Cedora Tech!'; // 预填的消息
      const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
      window.open(whatsappUrl, '_blank'); // 打开新的浏览器标签页
    },
  }
};
</script>

<style scoped>
.page-container {
  background-color: #121212;
  color: white;
  font-family: Arial, sans-serif;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.section-header {
  text-align: left;
  margin-bottom: 30px;
}

.section-header h2 {
  font-size: 2rem;
  color: #aaff00;
}

.section-header p {
  font-size: 1rem;
  color: #ccc;
}
.section-left {
  width: 36%;
  background-image: url('@/assets/images/usecaseBG1.png'); /* 设置背景图片路径 */
  background-position: top left; /* 背景图片定位于右上角 */
  background-repeat: no-repeat; /* 防止背景图片重复 */
  background-size: auto; /* 根据需要调整图片大小，例如 auto、cover 或 contain */
  padding: 50px 50px;
}
.use-cases-cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 每行固定两列 */
  gap: 20px; /* 卡片之间的间距 */
  margin-bottom: 50px;
}
.card {
  background: #1b1b1b;
  border-radius: 10px;
  text-align: center;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s;
}

.card:hover {
  transform: translateY(-10px);
}

.card .icon {
  width: 70px;
  height: 70px;
}

.card h3 {
  font-size: 1rem;
  font-weight: normal;
  color: #fff;
}

.section-right {
  width: 50%;
}

.business-startup-section {
  text-align: left;
}

.business-startup-section h3 {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.business-startup-section p {
  font-size: 1rem;
  margin-bottom: 40px;
  margin-right: 50px;
  color: #ccc;
}

.stats {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 40px;
}
.stats h4{
  font-weight: normal;
  height: 1px;
}
.stats-list{
  display: flex;
}
.stats-list > * {
  flex: 1; /* 每个子元素分配相同的宽度 */
  text-align: center; /* 根据需要添加，居中内容 */
}
.stat {
  text-align: center;
}

.stat h2 {
  font-size: 3rem;
  color: #aaff00;
}

.stat p {
  font-size: 0.9rem;
  color: #ccc;
}

.learn-more-button {
  background-color: #aaff00;
  color: #121212;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.learn-more-button:hover {
  background-color: #99e600;
}
.last-section {
  padding: 50px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.last-section-text {
  background-image: url('@/assets/images/footerBGM.png'); /* 设置背景图片路径 */
  background-position: center left; /* 背景图片定位于右上角 */
  background-repeat: no-repeat; /* 防止背景图片重复 */
  background-size: auto; /* 根据需要调整图片大小，例如 auto、cover 或 contain */
}

.last-section h1 {
  font-family: "Arial", sans-serif;
  font-weight: lighter;
  font-size: 2.1rem;
  margin-bottom: 20px;
  margin-left: 50px;
  margin-top: 50px;
}

.last-section p {
  font-family: "Arial", sans-serif;
  font-size: 0.9rem;
  margin-bottom: 30px;
  margin-left: 50px;
}

.highlight {
  color: #ffffff;
}

.highlight-accent {
  color: #aaff00;
}

.contact-button {
  width: 140px;
  height: 30px;
  line-height: 10px;
  margin-right: 5vw;
  background-color: #aaff00;
  color: #121212;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.contact-button:hover {
  background-color: #99e600;

}

.footer-section {
  margin-top: 50px;
  padding: 20px 0;
  border-top: 1px solid #333;
  display: flex;
  flex-direction: column;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
}

.footer-logo {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.footer-links {
  margin-bottom: 10px;
}

.footer-links a {
  color: white;
  margin: 0 10px;
  text-decoration: none;
  transition: color 0.3s;
}

.footer-links a:hover {
  color: #aaff00;
}

.footer-contact{
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.footer-contact-sub{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;
}

.footer-contact span {
  margin: 5px 0;
}
</style>
