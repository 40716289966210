<template>
  <section class="bg-gray-100 py-16">
    <div class="container mx-auto">
      <h2 class="our-services-title text-center mt-[100px]">Our <span style="color: #c1ff27;">Services</span></h2>
      <p class="description mt-4 text-sm">
        煦杉科技（香港）有限公司是一家致力於數字化創新與技術服務的專業科技公司。我們憑藉先進的技術與專業團隊，為全球客戶提供高效、可靠且量身定制的解決方案，幫助企業實現數字化轉型並提升市場競爭力。<br>
        CEDORA TECHNOLOGY (HONG KONG) LIMITED is a professional technology company specializing in digital innovation and technical services. <br> Leveraging advanced technologies and a skilled team, we deliver efficient, reliable, and customized solutions to <br> global clients, empowering businesses to achieve digital transformation and enhance their market competitiveness.
      </p>
      <div class="custom-margin mt-16">
        <ServiceCard />
      </div>
    </div>
  </section>
</template>

<script>
import ServiceCard from '@/components/ServiceCard.vue';

export default {
  components: { ServiceCard },
  data() {
    return {
      services: [
        { title: 'Software Development', description: 'We offer comprehensive software development services.' },
        { title: 'API Integration', description: 'Seamlessly integrate APIs for your business.' },
        { title: 'Data Analytics', description: 'Gain insights with cutting-edge analytics solutions.' },
      ],
    };
  },
};
</script>

<style scoped>
.our-services-title {
  width: 1296px;
  height: 72px;
  font-family: "Lexend", sans-serif;
  font-size: 48px;
  font-weight: 500;
  line-height: 72px;
  text-align: left;
  color: #fff;
}

.description {
  font-family: Lexend;
  font-size: 18px;
  font-weight: 300;
  line-height: 27px;
  text-align: left;
  color: #B3B3B3;
  margin-top:-35px;
}
.custom-margin {
  margin-top: 50px;
}
</style>
