<template>
  <div>
    <Navbar/>
    <router-view />
    <Footer />
  </div>
</template>

<script>
import Navbar from '@/components/APPNavbar.vue';
import Footer from '@/components/APPFooter.vue';

export default {
  components: { Navbar, Footer },
};
</script>

<style>
/* 全局背景样式 */
body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background-image: url('@/assets/images/backgroundLT01.png');
  background-repeat: no-repeat; /* 背景图片不重复 */
  background-position: top left; /* 背景图片从左上角开始放置 */
  background-size: auto; /* 保持图片原始尺寸 */
  color: #fff; /* 全局字体颜色 */
}

html,
body {
  height: 100%;
}

#app {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}


</style>
