<template>
<!--  -->
  <div class="service-section">
    <!-- 左侧导航菜单 -->
    <div class="service-menu">
      <button
          v-for="(menu, index) in menus"
          :key="index"
          :class="['menu-item', { active: activeMenu === menu }]"
          @click="activeMenu = menu"
      >
        {{ menu }}
      </button>
    </div>

    <!-- 右侧服务内容 -->
    <div class="service-cards">
      <div
          class="card"
          v-for="(service, index) in services[activeMenu]"
          :key="index"
      >
        <h3>
          {{ service.title }}
          <img src="@/assets/images/arrowRT.png" alt="">
        </h3>
        <p>{{ service.description }}</p>
        <p class="sub-description">{{ service.subDescription }}</p>
      </div>
    </div>
  </div>

<!--  -->

</template>

<script>
export default {
  props: {
    title: String,
    description: String,
  },
  data() {
    return {
      activeMenu: "Software Service",
      menus: ["Software Service", "Big Data & AI", "Management"],
      services: {
        "Software Service": [
          {
            title: "軟件開發 | Software Development",
            description:
                "我們提供全方位的軟件開發服務，從基礎架構設計到功能開發，滿足不同行業的需求。",
            subDescription:
                "We offer comprehensive software development services, from infrastructure design to feature development, tailored to diverse industry needs.",
          },
          {
            title: "軟件運維 | Software Maintenance",
            description:
                "專業的運維支持服務，確保系統穩定運行，並及時解決技術問題。",
            subDescription:
                "Professional maintenance and support services to ensure system stability and resolve technical issues promptly.",
          },
          {
            title: "新功能開發 | New Feature Development",
            description:
                "根據業務需求開發創新功能，提升產品價值和用戶體驗。",
            subDescription:
                "Developing innovative features based on business needs to enhance product value and user experience.",
          },
          {
            title: "API接口對接 | API Integration",
            description: "提供專業的API接口開發與對接服務，實現系統之間的無縫通信。",
            subDescription:
                "Offering professional API development and integration services to enable seamless system communication.",
          },
        ],
        "Big Data & AI": [
          {
            title: "數據管理與分析 | Data Management and Analytics",
            description:
                "提供全方位的數據收集、清洗和整合服務，支持多源數據的結構化和非結構化處理。部署高效、安全的數據存儲架構。",
            subDescription:
                "Provide comprehensive services for data collection, cleansing, and integration, supporting both structured and unstructured data processing from multiple sources.",
          },
          {
            title: "大數據預測與智能化應用 | Big Data Prediction and Intelligent Applications",
            description:
                "通過構建預測模型，幫助企業預測市場趨勢、銷售需求和潛在風險，提升業務敏捷性。",
            subDescription:
                "By building predictive models, we help businesses forecast market trends, sales demands, and potential risks.",
          },
          {
            title: "智能化業務流程 | Intelligent Business Automation",
            description:
                "定制自動化繁瑣的業務流程，例如數據錄入、報表生成和客服支持。",
            subDescription:
                "Customize the automation of tedious business processes, such as data entry, report generation, and customer support.",
          },
          {
            title: "AI解決方案定制 | Custom AI Solutions",
            description: "根據企業需求，設計專屬人工智能系統，涵蓋模型構建、優化和部署全過程。",
            subDescription:
                "We provide custom AI solutions tailored to business needs.",
          },
        ],
        "Management": [
          {
            title: "全面平台管理 | Comprehensive Platform Management",
            description:
                "針對企業需求提供全方位的平台建設和運營管理服務，包括市場定位、品牌形象設計與推廣策略。",
            subDescription:
                "Provide comprehensive platform development, operation, and management services tailored to business needs, including market positioning, brand image design, and promotional strategies.",
          },
          {
            title: "多渠道營銷推廣 | Multi-Channel Marketing and Promotion",
            description:
                "通過電子郵件行銷、社交媒體運營、SEO和SEM等多種渠道進行全方位的市場推廣。",
            subDescription:
                "Implement marketing campaigns across various channels, including email marketing, social media management, SEO, and SEM.",
          },
          {
            title: "內容創建與管理 | Content Creation and Management",
            description:
                "提供高品質的內容創建服務，包括網站內容、博客文章、視頻和圖像設計等。",
            subDescription:
                "Provide high-quality content creation services, including website content, blog posts, videos, and graphic design.",
          },
          {
            title: "數據分析與報告 | Data Analytics and Reporting",
            description: "利用先進的數據分析工具，對各項營銷活動進行數據追踪與效果評估。",
            subDescription:
                "Use DA tools to track and assess the effectiveness of marketing campaigns.",
          },
        ],
      },
    };
  },
};
</script>

<style scoped>


/**/
.service-section {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  color: #fff;
  background-color: #121212;
  padding: 40px;
  font-family: Arial, sans-serif;
}

.service-menu {
  flex: 1;
  max-width: 200px;
  display: flex;
  flex-direction: column;
}

.menu-item {
  background: none;
  border: 1px solid #444;
  color: #fff;
  padding: 10px 20px;
  margin-bottom: 10px;
  text-align: left;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 8px;
  transition: all 0.3s;
}

.menu-item.active,
.menu-item:hover {
  background-color: #aaff00;
  color: #121212;
}

.service-cards {
  flex: 3;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.service-cards h3{
  font-weight: normal;
}

.card {
  background: #1b1b1b;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s;
}

.card:hover {
  transform: translateY(-10px);
}

.card h3 {
  font-size: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
}

.card .arrow {
  color: #aaff00;
  font-size: 1.2rem;
  margin-left: 10px;
}

.card p {
  color: #ccc;
  font-size: 0.9rem;
  margin-top: 10px;
}

.card .sub-description {
  color: #888;
  font-size: 0.8rem;
}
</style>
